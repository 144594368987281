import React, {useState,useEffect} from 'react'
import {
    Button,
    TextField,
    Typography,
    Container,
    Box,
    Paper,
} from '@mui/material';
import Logo from "../../assets/images/logo.png";
import { jdfInstance } from '../../http/http';

function LoginPage() {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');


    // APIS  ========================================================================================

    async function submitLoginApi(){
        let formData = new FormData();

        formData.append("email", email);
        formData.append("password", password);
    
        const url = "auth/login.php";
        try {
          const response = await jdfInstance.post(url, formData);
          if (response.data.status === 201) {
            localStorage.setItem("session",response.data.session);
            window.location.href = '/';
          } else {
            window.alert(response.data.msg);
          }
        } catch (e) {
          console.log(e);
        }
    }


    // FUNCTIONS ========================================================================================


    function handleSubmit(e){
        e.preventDefault();
        if(email === ""){
            setError("Email cannot be empty!");
        }else if(password === ""){
            setError("Password cannot be empty!");
        }else{
            submitLoginApi();
        }
    }

    // RENDER ========================================================================================
    return (
        <div className="container mx-auto bg-gif">
            <Container
                component="main"
                maxWidth="xs"
                className="h-screen flex items-center justify-center"
            >
                <Paper
                    elevation={6}
                    className="p-6 w-full shadow-lg rounded-lg bg-white"
                >
                <Box
                    className="flex flex-col items-center"
                    sx={{ marginBottom: 2 }}
                >   
                    <img src={Logo} alt="logo" className="h-10 max-w-28" />
                    <Typography component="h1" variant="h5" className="mb-8">
                        Member Login
                    </Typography>
                    <div className='h-3'/>
                    <Box
                        component="form"
                        noValidate
                        className="w-full"
                        onSubmit={handleSubmit}
                        method='POST'
                    >
                        <TextField
                            variant="outlined"
                            required
                            margin='dense'
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <div className='h-2'/>
                        <TextField
                            variant="outlined"
                            margin='dense'
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        {error && (
                            <Typography
                            variant="body2"
                            color="error"
                            className="text-center mb-4"
                            >
                            {error}
                            </Typography>
                        )}
                        <div className='h-4'/>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className="mt-6 mb-2 text-white font-bold"
                        >
                            Sign In
                        </Button>
                    </Box>
                </Box>
                </Paper>
            </Container>
        </div>
    );
}

export default LoginPage;