import './App.css';
import {
  createBrowserRouter,
  RouterProvider,
  useRouteError
} from "react-router-dom";
import React, {useState} from "react";
import { ThemeProvider,createTheme } from '@mui/material/styles';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';

import HomePage from './modules/home/home';
import LoginPage from './modules/login/login';
import SignupPage from './modules/signup/signup';
import PrivacyPolicyPage from './modules/privacy-policy/page';
import CoePage from './modules/code-of-ethics/page';
import MembershipRulesPage from './modules/membership-rules/page';
import TermsOfUsePage from './modules/terms-of-use/page';

//check if login
function checkIsLogin(){
    if(localStorage.getItem("session")){
        return true;
    }else{
        return false;
    }
}


const theme = createTheme({
    jdfBlue: {
        main: "#1d75c6",
        contrastText:"#fff"
    },
    palette: {
      primary: {
        main: '#3b82f6', // Tailwind Blue
      },
      secondary: {
        main: '#14b8a6', // Tailwind Teal
      },
      jdfBlue: {
        main: "#1d75c6"
      }
    },
    typography: {
      fontFamily: 'Inter, sans-serif',
    },
  });
  

function App() {
    const [snackbarOpen,setSnackbarOpen] = useState(false);
    const [snackbarMessage,setSnackbarMessage] = useState("");
    const [snackbarColor, setSnackbarColor] = useState("primary");

    function windowAlert(msg = "", color = "primary"){
        setSnackbarMessage(msg);
        setSnackbarOpen(true);
        setSnackbarColor(color);
    }

    function handleSnackbarClose(){
        setSnackbarOpen(false);
    }

    const router = createBrowserRouter([
        {
            path: "/",
            element: checkIsLogin() ? <HomePage windowAlert={windowAlert} />:<LoginPage windowAlert={windowAlert} />,
        },
        {
            path: "/login",
            element: <LoginPage windowAlert={windowAlert} />,
        },
        {
            path: "/register/:displayMemberId",
            element: <SignupPage windowAlert={windowAlert} />,
            errorElement:<ErrorBoundary />
        },
        {
            path: "/privacy-policy",
            element: <PrivacyPolicyPage windowAlert={windowAlert} />, 
        },
        {
            path: "/terms-of-use",
            element: <TermsOfUsePage windowAlert={windowAlert} />, 
        },
        {
            path: "/code-of-ethics",
            element: <CoePage windowAlert={windowAlert} />, 
        },
        {
            path: "/membership-rules",
            element: <MembershipRulesPage windowAlert={windowAlert} />, 
        },
        {
            path: "*",
            element: <ErrorBoundary />,
        }
    ],{});

    function ErrorBoundary() {
        return (
            <div className='container mx-auto w-full'>
                <div className='text-center mt-64'>
                    <h1 className='text-2xl'>Please click the button below to be redirected!</h1>
                    <Button variant="contained" onClick={()=>window.location.href = '/'}>Click Me</Button>
                </div>
            </div>
        );
    }

    return (
        <ThemeProvider theme={theme}>
            <RouterProvider router={router} />
            <Snackbar
                anchorOrigin={{vertical:"bottom",horizontal:"right"}}
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={handleSnackbarClose}
            >
            <Alert
                severity={snackbarColor}
                variant="filled"
                sx={{ width: '100%' }}
            >
                {snackbarMessage}
            </Alert>
            </Snackbar>
        </ThemeProvider>
    );
}

export default App;
