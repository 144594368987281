function PrivacyPolicyPage(){
    return(
        <div class="max-w-4xl mx-auto p-6 bg-white shadow-lg rounded-lg my-8">
        <h1 class="text-2xl font-bold mb-4">Privacy Policy</h1>
        
        <h2 class="text-xl font-semibold mb-3">1. Personal Data</h2>
        <p class="mb-3">As used in this Policy:</p>
        <ul class="list-disc ml-6 mb-4">
          <li class="mb-2"><strong>“customer”</strong> means an individual who (a) has contacted us through any means to find out more about any goods or services we provide, or (b) may, or has, entered into a contract with us for the supply of any goods or services by us; and</li>
          <li class="mb-2"><strong>“personal data”</strong> means data, whether true or not, about an individual who can be identified: (a) from that data; or (b) from that data and other information to which we have or are likely to have access.</li>
        </ul>
        
        <h2 class="text-xl font-semibold mb-3">2. Collection, Use and Disclosure of Personal Data</h2>
        <p class="mb-4">We generally do not collect your personal data unless it is provided to us voluntarily by you directly or via a third party who has been duly authorised by you to disclose your personal data to us after you (or your authorised representative) have been notified of the purposes for which the data is collected, and you (or your authorised representative) have provided written consent to the collection and usage of your personal data for those purposes, or collection and use of personal data without consent is permitted or required by the PDPA or other laws.</p>
        
        <h2 class="text-xl font-semibold mb-3">3. Consent</h2>
        <p class="mb-4">If you choose to register or participate in services or events conducted by JDF, you are consenting to the collection, use, and disclosure of your personal data in accordance with this Policy.</p>
        
        <h2 class="text-xl font-semibold mb-3">4. Links to Other Websites</h2>
        <p class="mb-4">Our website may contain links to other websites that are not operated by us. We are not responsible for the privacy practices of websites not operated by us.</p>
        
        <h2 class="text-xl font-semibold mb-3">5. Data Subject Rights</h2>
        <p class="mb-4">You have rights under the law in relation to your personal data, including the right to access, correct, and in some cases delete your personal data.</p>
        
        <h2 class="text-xl font-semibold mb-3">6. Withdrawal of Consent</h2>
        <p class="mb-4">You may withdraw your consent for the collection, use, and disclosure of your personal data at any time by contacting us at the details provided below.</p>
        
        <h2 class="text-xl font-semibold mb-3">7. Access to and Correction of Personal Data</h2>
        <p class="mb-4">If you wish to request access to or correction of your personal data which we hold, you may contact our Data Protection Officer at the details provided below.</p>
        
        <h2 class="text-xl font-semibold mb-3">8. Protection of Personal Data</h2>
        <p class="mb-4">We take reasonable precautions to protect your personal data from unauthorized access, collection, use, disclosure, copying, modification, disposal, or similar risks.</p>
        
        <h2 class="text-xl font-semibold mb-3">9. Retention of Personal Data</h2>
        <p class="mb-4">We will retain your personal data as long as necessary to fulfill the purposes for which it was collected or as required by applicable laws.</p>
        
        <h2 class="text-xl font-semibold mb-3">10. Transfers of Personal Data Outside of Singapore</h2>
        <p class="mb-4">We may transfer your personal data outside of Singapore in accordance with the PDPA and will take steps to ensure that your personal data continues to receive a standard of protection that is at least comparable to that provided under the PDPA.</p>
        
        <h2 class="text-xl font-semibold mb-3">11. Data Protection Officer</h2>
        <ul class="list-disc ml-6 mb-4">
          <li class="mb-2">Name: Managing Director</li>
          <li class="mb-2">Contact No.: +65 9711 6592</li>
          <li class="mb-2">Email: jdfasia@gmail.com</li>
        </ul>
        
        <h2 class="text-xl font-semibold mb-3">12. Effect of Policy and Changes to Policy</h2>
        <p class="mb-4">This Policy applies in conjunction with any other policies, contractual clauses and consent clauses that apply in relation to the collection, use and disclosure of your personal data by us.</p>
        <p class="mb-4">We may revise this Policy from time to time without any prior notice. Your continued use of our services constitutes your acknowledgement and acceptance of such changes.</p>
        
        <p class="mb-4">Effective date: 02 December 2024</p>
        <p class="mb-4">Last updated: 02 December 2024</p>
      </div>
      
    );
}

export default PrivacyPolicyPage;