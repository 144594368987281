import axios from "axios";

export const jdfInstance = axios.create({
    baseURL: 'https://remai.jdfasia.com/apis/',
    timeout: 5000,
    headers: {
        "Content-Type":"multipart/form-data"
    }
});


jdfInstance.interceptors.request.use((config)=>{
    config.headers.set("jdf-auth",localStorage.getItem("session")??"");
    return config;
});

jdfInstance.interceptors.response.use((response)=>{
    if(response.data.status === "500"){
        localStorage.clear();
        window.location.href = "/login";
    }
    return response;
});