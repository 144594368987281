import React, { useEffect, useState} from 'react'
import Screen from '../shared/screen';
import { jdfInstance } from '../../http/http';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import QRCode from "react-qr-code";
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Edit from '@mui/icons-material/Edit';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Tooltip } from '@mui/material';
import Warning from '@mui/icons-material/Warning';
import Check from '@mui/icons-material/Check';
import StarIcon from '@mui/icons-material/Star';

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  };

function HomePage(props) {
  
    const [profile,setProfile] = useState({members:[],email:""});
    const [currentSelectedMember, setCurrentSelectedMember] = useState({display_member_id:""});
    const [showEditModal,setShowEditModal] = useState(false);

    useEffect(()=>{
        getUserProfile();
    },[]);

    // APIS  ========================================================================================

     async function getUserProfile(){
        let formData = new FormData();
    
        const url = "accounts/getUserProfile.php";
        try {
          const response = await jdfInstance.post(url, formData);
          console.log(response.data);
          if (response.data.status === 201) {
            setProfile(response.data.result);
          } else {
            window.alert(response.data.msg);
          }
        } catch (e) {
          console.log(e);
        }
    }

    async function updateUserProfile(){

    }

    // FUNCTIONS ========================================================================================

    function handleModalClose(){
        setShowEditModal(false);
        setCurrentSelectedMember({});
    }

    function handleSubmitEdit(e){
        e.preventDefault();

    }

    function handleOnChange(val,origin){
        let memberDetails = {...currentSelectedMember};
        memberDetails[origin] = val;
        setCurrentSelectedMember(memberDetails);
    }

    // RENDER ========================================================================================
    function renderStatusDisplay(statusDisplay){
        switch(statusDisplay){
            case "INACTIVE": return <strong style={{color:"#ed6c02"}}>{statusDisplay}</strong>;
            case "BOSS": return <strong style={{color:"#2196f3"}}>{statusDisplay}</strong>
            case "ACTIVE": return <strong style={{color:"#4caf50"}}>{statusDisplay}</strong>
            default: return <strong >{statusDisplay}</strong>
        }
    }

    return (
        <Screen pageSlug="home">
            <div className='container mx-auto px-4 py-4'>
                <h3 className='text-xl font-semibold'>Welcome back, {profile.email}</h3>
                <br/>
                <h6>Your linked members:</h6>
                {
                    profile.members.map((profile,index)=>{
                        return(
                            <Accordion key={index}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    id={`panel${index}-header`}
                                >
                                    {profile.member_name} ({profile.display_member_id}),  Cash: SGD <strong className='ms-2'>{profile.cash_balance}</strong>, DT: <strong className='ms-2'>{profile.dt_balance}</strong>
                                    <div className='ml-2'>
                                        {profile.status === "inactive" && <Tooltip title="Account is inactive"><Warning color="warning"/></Tooltip>}
                                        {profile.status === "active" && <Tooltip title="Account is active"><Check color="success"/></Tooltip>}
                                        {profile.status === "boss" && <Tooltip title="Account is Boss"><StarIcon color="primary"/></Tooltip>}
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div className='container mx-auto'>
                                        {profile.status !== "inactive" && <div>
                                            <div className='flex justify-items-center w-full mb-3'>
                                                <div className='max-w-40 mx-auto'>
                                                    <QRCode
                                                        size={256}
                                                        style={{ height: "auto", maxWidth: "100%", width: "50%", margin:"0 auto" }}
                                                        value={`https://remai.jdfasia.com/register/${profile.display_member_id}`}
                                                        viewBox={`0 0 256 256`}
                                                    />
                                                    <div className='text-center mt-3'>
                                                        <Button variant='contained' color='primary' onClick={()=>{
                                                            let url = `https://remai.jdfasia.com/register/${profile.display_member_id}`;
                                                            window.navigator.clipboard.writeText(url);
                                                            props.windowAlert("Referral link copied!","success");
                                                        }}>Copy Link</Button>
                                                    </div>
                                                </div>
                                            </div>
                                            <Divider />
                                        </div>}
                                        <div className='mx-auto px-2 py-2'>
                                            <h5 className='font-semibold text-lg'>Member Details</h5>
                                            <p><strong>Sponsored By:</strong> <strong>{profile.upline_display_id}</strong></p>
                                            <p><strong>Status:</strong> {renderStatusDisplay(profile.status_display)}</p>
                                            <p><strong>Package:</strong> {profile.package_details.package_name} ({profile.package_details.package_description})</p>
                                            <p><strong>PV:</strong> {profile.pv}</p>
                                            <p><strong>Gender:</strong> {profile.gender.toUpperCase()}</p>
                                            <p><strong>Race:</strong> {profile.race.toUpperCase()}</p>
                                            <p><strong>Mobile:</strong> {profile.mobile_number}</p>
                                            <p><strong>Address:</strong> {profile.address}</p>
                                            <p><strong>Country:</strong> {profile.country}</p>
                                            <p><strong>Bank:</strong> {profile.bank_name === ""?"-":profile.bank_name}</p>
                                            <p><strong>Bank Account:</strong> {profile.bank_account === ""?"-":profile.bank_account}</p>
                                            <div className='text-center'>
                                                <Button variant="contained" color="info" startIcon={<Edit />} onClick={()=>{
                                                    //show modal
                                                    setCurrentSelectedMember(profile);
                                                    setShowEditModal(true);
                                                }}>Edit Detail</Button>
                                            </div>
                                        </div>
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        )
                    })
                }
            </div>
            <Modal
                open={showEditModal}
                onClose={handleModalClose}
            >
                <Box 
                    sx={modalStyle} 
                    component="form"
                    noValidate
                    className="w-full"
                    onSubmit={handleSubmitEdit}
                    method='POST'
                >
                   <h5 className='text-xl font-semibold mb-2'>Edit Member ({currentSelectedMember.display_member_id})</h5>
                   <Divider />
                   <div className='h-2'/>
                   <TextField
                        variant="outlined"
                        margin='dense'
                        fullWidth
                        id="bank"
                        label="Bank Name"
                        name="bank"
                        autoComplete="bank"
                        autoFocus
                        value={currentSelectedMember.bank_name}
                        onChange={(e) => handleOnChange(e.target.value,"bank_name")}
                    />
                    <div className='h-1'/>
                    <TextField
                        variant="outlined"
                        margin='dense'
                        fullWidth
                        id="bank_account"
                        label="Bank Account"
                        name="bank_account"
                        autoComplete="bank_account"
                        autoFocus
                        value={currentSelectedMember.bank_account}
                        onChange={(e) => handleOnChange(e.target.value,"bank_account")}
                    />
                    <div className='h-3'/>
                    <div className='text-center'>
                        <Button variant="contained" color="success" className='font-bold' onClick={()=>{
                            
                        }}>Submit Changes</Button>
                    </div>
                </Box>
            </Modal>
        </Screen>
    );
}

export default HomePage;