function CoePage(){
    return(
        <div class="max-w-4xl mx-auto p-6 bg-white shadow-lg rounded-lg my-8">
  <h1 class="text-2xl font-bold mb-4">Code of Ethics</h1>
  
  <h2 class="text-xl font-semibold mb-3">1. The Company’s Statement</h2>
  <p class="mb-4"><strong>Personal and organizational integrity</strong> are critical in our direct sales activities and our interaction with Consumers. This <strong>Code of Ethics</strong> (this “Code”) provides ethical guidelines for members of the JDF Rewards Programme (“members”) who wish to become Direct Sellers so as to promote and market JDF Asia packages directly to Consumers. It also provides guidelines that may be relevant to their participation in the JDF Rewards Programme and their use of and participation in the Portal (i.e., the JDF Rewards Programme members’ portal) in connection with the JDF Rewards Programme.</p>
  <p class="mb-4">This Code cannot address every issue that members may encounter but it does provide guidance and resources for members, especially during those times when the right choice is not clear. Additional information, including relevant rules and policies, may be found in the Membership Agreement, i.e., 1) <a href="/terms-of-use" class="text-blue-500 underline" target="_blank">Terms of Use</a>; 2) <a href="/privacy-policy" class="text-blue-500 underline" target="_blank">Privacy Policy</a>; 3) <a href="/membership-rules" class="text-blue-500 underline" target="_blank">Membership Rules</a>; this Code and such other rules and policies as may be notified by the Company from time to time. Members can also contact the Company or their upline member should they need further guidance.</p>
  
  <h2 class="text-xl font-semibold mb-3">2. Definitions</h2>
  <ul class="list-disc ml-6 mb-4">
    <li class="mb-2"><strong>Company:</strong> JDF Asia Pte. Ltd., which is a business entity promoting the direct sales of Products to Consumers, and the administration and operation of the JDF Rewards Programme via the Portal.</li>
    <li class="mb-2"><strong>Consumer:</strong> Any person who purchases, utilises, and consumes a JDF Asia package from a member or from JDF.</li>
    <li class="mb-2"><strong>Direct Seller:</strong> A JDF Rewards Programme member (Direct Seller) who has committed to selling JDF Asia Packages as an independent contractor, retailer, distributor, or self-employed representative.</li>
    <li class="mb-2"><strong>Products:</strong> Products and/or services which Direct Sellers directly market and sell to Consumers.</li>
    <li class="mb-2"><strong>Recruiting:</strong> Any activity directed, administered, or marketed for the purpose of assisting any individual in becoming a Direct Seller of JDF Asia packages.</li>
  </ul>

  <h2 class="text-xl font-semibold mb-3">3. Conduct for the Protection of Consumers</h2>
  <h3 class="text-lg font-semibold mb-2">3.1 Restricted/Prohibited Practices</h3>
  <p class="mb-4">Direct Sellers shall not engage in any misleading, deceptive, false, or unfair sales practices toward Consumers, including spreading distorted messages or other communication outside the purview of JDF.</p>

  <h3 class="text-lg font-semibold mb-2">3.2 Identification</h3>
  <p class="mb-4">At the beginning of any sales presentation to a prospective Consumer, Direct Sellers shall immediately and clearly introduce themselves and the Company (i.e., JDF Asia Pte. Ltd.) they represent; the nature and characteristics of the Products/Packages they have available for promotion and solicitation towards the prospective Consumers.</p>

  <h3 class="text-lg font-semibold mb-2">3.3 Explanation and Demonstration</h3>
  <p class="mb-4">Direct Sellers shall offer Consumers full and accurate details of the Products, which include: price, member application requirements, cooling-off period, and cancellation procedures. They shall only make verbal or written statements permitted by the Company.</p>

  <h3 class="text-lg font-semibold mb-2">3.4 Marketing Materials</h3>
  <p class="mb-4">Marketing and promotional materials shall not contain exaggerated, false, or misleading descriptions, claims, or illustrations. Such materials must include the name, business address, email, and contact number of the Company.</p>

  <h3 class="text-lg font-semibold mb-2">3.5 Respect of Privacy</h3>
  <p class="mb-4">Direct Sellers shall respect Consumers' privacy rights, making personal visits or calls only when welcome and ceasing activities immediately upon request.</p>

  <h3 class="text-lg font-semibold mb-2">3.6 Fairness</h3>
  <p class="mb-4">Direct Sellers shall not exploit Consumers' trust, commercial inexperience, or vulnerabilities such as old age or language barriers. They shall treat Consumers with honesty and integrity.</p>

  <h2 class="text-xl font-semibold mb-3">4. Complaint or Dispute Handling</h2>
  <p class="mb-4">Complaints or disputes arising from the Direct Seller or Consumer shall be resolved per the Membership Rules. The Company may act as a mediator to resolve disputes amicably.</p>

  <h2 class="text-xl font-semibold mb-3">5. Effective Date</h2>
  <p class="mb-4">Effective date: 02 December 2024</p>
  <p>Last updated: 02 December 2024</p>
</div>


    );
}

export default CoePage;