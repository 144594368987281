function MembershipRulesPage(){
    return(
<div class="max-w-4xl mx-auto p-6 bg-white shadow-lg rounded-lg my-8">
  <h1 class="text-2xl font-bold mb-4">Membership Rules - JDF Rewards Programme</h1>
  <p class="mb-4">These JDF Rewards Programme Membership Rules (“Membership Rules” or these “Rules”) herein set out the rules and regulations that regulate the activities of JDF Rewards Programme members (each a “member”) as participants of the Portal for the purposes of activities relating to or in connection with the JDF Rewards Programme, including but not limited to, buying the Products offered and/or listing their Products through the Portal.</p>
  
  <h2 class="text-xl font-semibold mb-3">Part A – Rules on Membership Matters Applicable to All Members</h2>
  <p class="mb-4">All members (including tentative members) are required to comply with the <strong>Terms of Use</strong>, <strong>Privacy Policy</strong>, these <strong>Membership Rules</strong>, and the <strong>Code of Ethics</strong>. Any member may be subject to disciplinary procedures, including censure, suspension, or expulsion, for violation of such Membership Agreement.</p>
  
  <h2 class="text-xl font-semibold mb-3">1. Definitions</h2>
  <ul class="list-disc ml-6 mb-4">
    <li class="mb-2"><strong>“we”, “our”, “us”</strong> or <strong>“JDF”</strong> means JDF Asia Pte. Ltd.</li>
    <li class="mb-2"><strong>“you”, “your”, “user”</strong> means any Member who accesses and/or uses JDF’s Portal, and any services offered through this Portal.</li>
    <li class="mb-2"><strong>Consumer:</strong> Any person who purchases a Package from a Member or from JDF.</li>
    <li class="mb-2"><strong>Member:</strong> An individual or company who is a Consumer of JDF Asia Packages or Products.</li>
    <li class="mb-2"><strong>Products:</strong> Products and/or services available for redemption on the JDF Portal by companies or Vendors who are responsible for the performance and fulfilment of such products and/or services to you pursuant to an agreement.</li>
    <li class="mb-2"><strong>Packages:</strong> The set of grouped products and/or services at a fixed retail price agreed upon between Vendors and JDF Asia for purchase by incoming Members.</li>
  </ul>

  <h2 class="text-xl font-semibold mb-3">2. Becoming a Member of JDF Rewards Programme</h2>
  <p class="mb-4">You must be at least 18 years old to be eligible for JDF Rewards Programme membership. You must pay for a JDF Asia package whereupon you will be given a URL link to log in to register as a member of JDF Rewards Programme. You will be required to pay the administrative fee and bank charges when making payment for the JDF Asia package. Upon logging in to the URL link provided, you will be invited to complete and submit the JDF Rewards Programme membership form by creating a User ID and JDF Portal password. After you have submitted the membership form, you will become a tentative member of JDF Rewards Programme.</p>

  <h2 class="text-xl font-semibold mb-3">3. Management of the JDF Asia Package</h2>
  <p class="mb-4">The details of the JDF Asia package can be found on the Portal. If you wish to make use of the services (wellness) under the JDF Asia package, you need to visit the Service Centre, currently located in Kuala Lumpur, Malaysia, as indicated on the Site. Please check the Site for any updates to the location of Service Centres. You need to log in to the Portal in order to book services under the JDF Asia package at the location that has available timeslots.</p>

  <h2 class="text-xl font-semibold mb-3">4. Referral Selling</h2>
  <p class="mb-4">Only members may sell JDF Asia packages to their contacts or referrals provided that they abide by the Membership Agreement at all times and have been authorized by JDF or their respective upline members. Before members start marketing or selling the JDF Asia package, they must ensure that they have adequate knowledge of the JDF Asia package; they identify JDF as the provider of the JDF Asia package, providing its address and telephone number; they obtain marketing materials from JDF or from their upline members and shall not use their own unauthorized materials.</p>

  <h2 class="text-xl font-semibold mb-3">Part B – Rules Governing Members Who Sell on the JDF Mall (“JDF Mall Terms”)</h2>
  <p class="mb-4">In addition to the rules set out in these Membership Rules (See: Part A), any Member, as an individual or as a company, who wishes to list his/her Products on the JDF Mall or as a JDF Asia Package must accept these JDF Mall Terms in these Membership Rules (See: Part B) and such policies as may be published by JDF on the Portal (the “Policies”).</p>

  <p class="mb-4">Effective date: 02 December 2024</p>
  <p>Last updated: 02 December 2024</p>
</div>


    );
}

export default MembershipRulesPage;