function TermsOfUsePage(){
    return(
<div class="max-w-4xl mx-auto p-6 bg-white shadow-lg rounded-lg my-8">
  <h1 class="text-2xl font-bold mb-4">Terms of Use</h1>
  
  <h2 class="text-xl font-semibold mb-3">1. TERMS OF USE</h2>
  <p class="mb-4">If you do not agree to these Terms of Use, do not access and/or use this Site (defined below), the Portal (defined below) or the Services (defined below). These Terms of Use stipulate what you may do and not do when accessing and using this Site, the Portal and the Services.</p>
  
  <h2 class="text-xl font-semibold mb-3">2. Agreement – Terms</h2>
  <p class="mb-4">Welcome to the jdfasia.com website (this “Site”) operated by JDF Asia. Please read these Terms of Use carefully. Your access to any area of this Site, use and access of the Portal, including the use of the Services, is governed by the terms and conditions below (these “Terms”).</p>
  <p class="mb-4">If you do not accept any of these Terms, exit immediately. Continue only if you accept these Terms. These Terms apply to all visitors, users and others who access and/or use this Site, the Portal and the Services. Additional terms and conditions apply to the access and/or use of the Portal and the Services.</p>
  
  <h2 class="text-xl font-semibold mb-3">3. Definitions & Interpretation</h2>
  <p class="mb-4">“we”, “our, “us”, “JDF” means JDF Asia Pte. Ltd.</p>
  <p class="mb-4">“you”, “your”, “user” means any person who accesses and/or uses this Site, and for a person who has registered with us as a member or with an account, such person accessing or using the Portal and/or the Services.</p>
  
  <h2 class="text-xl font-semibold mb-3">4. General use and/or access of this Site and the Portal</h2>
  <p class="mb-4">You agree to access and/or use this Site, the Portal and the Services only for lawful purposes and in a lawful manner at all times and further agree to conduct any activity relating to the Services in good faith; and to ensure that any information or data you post or cause to appear on the Portal in connection with the Products is accurate and agree to take sole responsibility for such information and data.</p>
  
  <h2 class="text-xl font-semibold mb-3">5. Privacy Policy</h2>
  <p class="mb-4">Your use of this Site, the Services and/or access to the Portal is also subject to the Privacy Policy as set out at <a href="https://www.jdfasia.com/privacy-policy" class="text-blue-500 underline" target="_blank">Privacy Policy</a>.</p>
  
  <h2 class="text-xl font-semibold mb-3">6. Registration as Member of JDF Rewards Programme</h2>
  <p class="mb-4">You may inquire about JDF Rewards Programme by contacting an existing member or by contacting this telephone number: +65 97116592. If you submit your registration to become a member of JDF Rewards Programme, in addition to these Terms, additional terms and conditions will apply to you when you are accepted as a member of the JDF Rewards Programme.</p>
  
  <h2 class="text-xl font-semibold mb-3">7. Operation of this Site and the Portal</h2>
  <p class="mb-4">The accessibility and operation of this Site, the Portal and/or the Services relies on technologies outside our control. We do not guarantee continuous accessibility or uninterrupted operation of this Site, the Portal and/or the Services.</p>
  
  <h2 class="text-xl font-semibold mb-3">8. Intellectual Property</h2>
  <p class="mb-4">Ownership: The Intellectual Property in and to this Site, the Portal and/or the Services and the materials are owned, licensed to or controlled by us, our licensors, our service providers, or the Sellers (as applicable). We reserve the right to enforce the Intellectual Property to the fullest extent of the law.</p>
  
  <h2 class="text-xl font-semibold mb-3">9. General Disclaimer And Limitation Of Liability</h2>
  <p class="mb-4">JDF updates the information available on this Site and the Portal as regularly as is practicable. However, JDF cannot guarantee, and accepts no legal liability whatsoever arising from or connected to, the accuracy, reliability, currency or completeness of any material contained on this Site, the Portal and the Services.</p>
  
  <h2 class="text-xl font-semibold mb-3">10. Fee</h2>
  <p class="mb-4">There are no fees for using this Site. We are not responsible for any fees charged by any other Internet site (not provided by us).</p>
  
  <h2 class="text-xl font-semibold mb-3">11. Termination</h2>
  <p class="mb-4">We may terminate or suspend access to this Site, the Portal and/or the Services immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach these Terms.</p>
  
  <h2 class="text-xl font-semibold mb-3">12. Applicable Laws</h2>
  <p class="mb-4">Use of this Site, the Portal and/or the Services and these Terms are governed by the laws of Singapore. Any claim relating to the use of this Site, the Portal and/or the Services or against the operator thereof shall be finally heard by Singapore Courts.</p>
  
  <h2 class="text-xl font-semibold mb-3">13. Variation</h2>
  <p class="mb-4">We may revise these Terms at any time by updating this page. You should visit this page from time to time and review the then current Terms because they are binding on you.</p>
  
  <h2 class="text-xl font-semibold mb-3">14. General</h2>
  <p class="mb-4">This section provides information on cumulative rights and remedies, no waiver, severability, rights of third parties, injunctive relief, amendments, assignment, force majeure, and conflict of terms.</p>
  
  <h2 class="text-xl font-semibold mb-3">15. Contact Us</h2>
  <p class="mb-4">If you have any questions about these Terms, please contact us at:</p>
  <ul class="list-disc ml-6">
    <li class="mb-2">Name: Managing Director</li>
    <li class="mb-2">Contact No.: +65 9711 6592</li>
    <li class="mb-2">Email Address: jdfasia@gmail.com</li>
  </ul>
  
  <p class="mb-4">Effective date: 02 December 2024</p>
  <p>Last updated: 02 December 2024</p>
</div>


    );
}

export default TermsOfUsePage;